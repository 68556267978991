document.addEventListener("DOMContentLoaded", function () {
  function change_form_label(label, input) {
    if ("" !== input.value) {
      label.classList.add("active");
    }

    label.addEventListener("click", function () {
      input.focus();
    });

    input.addEventListener("focus", function () {
      label.classList.add("active");
    });

    input.addEventListener("focusout", function () {
      if (!input.value || "(___) ___-____" === input.value) {
        label.classList.remove("active");
      }
    });
  }

  const input = document.querySelector(
    '.footer__columns .tnp-subscription .tnp-field-email input[type="email"]'
  );
  const label = document.querySelector(
    ".footer__columns .tnp-subscription .tnp-field-email label"
  );

  if (input && label) {
    change_form_label(label, input);
  }
});
